export const DefaultAWSData = {
  aiRequestDetails: {
    globalId: "",
    email: "",
    applicationName: "",
    applicationType: "",
    applicationType_others: "",
    haveCloudAccount: "",
    dontHaveCloudAccount: "",
    cloudAccountNumber: "",
    region: "",

    billingOpco: "",
    billingSubOpco: "",
    billingDepartment: "",
    billTo: "",
    billingIo: "",
    gsid: "",
    costCenter: "",
    billingRegion: "",

    useCaseDescription: "",
    cloudService: "",
    programmingLanguage: "",
    scopeOfUsers: "",
    typeOfDataProcessed: "",
    typeOfTasks: "",
    typeOfTasks_others: "",
    programmingLanguage_others: "",

    aiUsageType: "",
    toolsDistribution: "",
    llmModel: "",

    businessJustification: "",
    qualityControlConsent: "",
    typeOfEnvironment: "",
    dataTypeUsedForPOC: "",
    categoryOfData: "",
    distributedOutputTools: "",
    billingEmail: "",
    billingEmailAccoutNo: "",

    additionalComments: "",
    supportingDocumentName: "",
    documentBaseFile: "",
    requestStatus: "",
    uid: "",
    submittedBy: "",
  },
};

export const AWSReducer = (awsData, { type, payload }) => {
  switch (type) {
    case "UPDATE_AI_REQUEST":
      return {
        ...awsData,
        aiRequestDetails: {
          ...awsData.aiRequestDetails,
          ...payload,
        },
      };

    default:
      return awsData;
  }
};
