import React from "react";
import { createRoot } from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18";
// REDUX
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import { toastConfig } from "components/Notification";
import "./base.css";
import { Buffer } from "buffer";

if (!window.Buffer) {
  window.Buffer = Buffer;
}

const queryConfig = {
  shared: {
    suspense: false,
  },
  queries: {
    retry: 0,
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
  },
};

const queryClient = new QueryClient({
  defaultOptions: queryConfig,
});

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ToastContainer {...toastConfig} />
      <BrowserRouter>
        <React.Suspense fallback="...">
          <App />
        </React.Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

const root = createRoot(document.getElementById("root"));
root.render(app);
serviceWorker.unregister();
