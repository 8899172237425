import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";

// Import Icon
import dots from "icons/3-dots.svg";

import Loader from "components/Common/Loader";
import CustomModal from "components/Modal/CustomModal";

// Import Internal Components
import AccountView from "./AccountView";
import AccountEdit from "./AccountEdit";
import AccountApprove from "./AccountApprove";
import AccountDecline from "./AccountDecline";
import AccountCancel from "./AccountCancel";

// API Call
import { postApiCall } from "helper/postApiCall";

import { ACCOUNT_REQUEST_RELOAD } from "redux/account-requests/accountRequestConstant";
import { Link } from "react-router-dom";

import {
  CS_Hub_AWS_Account_Request_Admin,
  CS_Hub_Security_Admin_Branch_2,
  CS_Hub_AWS_Dynamo_Search_Request_Admin,
} from "roles/roles";
import BulkAccountEdit from "./BulkEdit/BulkAccountEdit";

// Import Icons
import backicon from "icons/back.svg";

let checkedItemsArr = [];

const AccountListTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Redux State
  const user = useSelector((state) => state.user);
  const { userInfo } = user;

  // Table State
  const [Loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [loaderMsg, setLoaderMsg] = useState("");

  //bulk update
  const [isBulkUpdate, setBulkUpdate] = useState(false);
  const [showBulkUpdateEdit, setBulkUpdateEdit] = useState(false);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const accountRequestReload = useSelector(
    (state) => state.accountRequestReload
  );
  const { accountRequestReloadType } = accountRequestReload;

  //bulk edit
  const bulkEditCallback = useCallback((value) => {
    setBulkUpdateEdit(value);
  }, []);

  // API URL
  const apiUrl = `${process.env.REACT_APP_ACCOUNT_LIST_URL}`;

  // ID Token
  const idToken = `${userInfo.idToken}`;

  // Request Body
  const requestBody =
    userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ||
    userInfo.groups.includes(CS_Hub_AWS_Dynamo_Search_Request_Admin)
      ? `{"type":"azure", "type_of_user":1}`
      : `{"Email":"${userInfo.email}", "type":"azure", "type_of_user":0}`;

  const fetchTableData = useCallback(() => {
    setLoading(true);
    setOptions({
      showOption: null,
      selectedOptionUID: null,
      selectedOptionData: {},
    });
    postApiCall(apiUrl, idToken, requestBody).then((response) => {
      setResponseData(response.data);
      checkedItemsArr = [];
      setCheckedItems([]);
      setSelectAllCheckBox(false);
      setBulkUpdateEdit(false);
      setLoading(false);
    });
  }, [apiUrl, idToken, requestBody]);

  // ! Option Part
  const [options, setOptions] = useState({
    showOption: null,
    selectedOptionUID: null,
    selectedOptionData: {},
  });

  // Option Click Handler
  function getAccountDetails(selectedOptionDataValues, type) {
    const viewApiUrl = `${process.env.REACT_APP_ACCOUNT_VIEW_URL}`;
    const viewApiRequestBody = `{"type" : "azure", "accountnumber":"${
      selectedOptionDataValues.accountnumber
    }",  "type_of_user": ${
      userInfo.groups.includes(CS_Hub_AWS_Account_Request_Admin) ? 1 : 0
    }}`;
    let accountDetails = null;
    setLoading(true);
    setLoaderMsg("Loading account details...");
    postApiCall(viewApiUrl, idToken, viewApiRequestBody).then((response) => {
      accountDetails = response.data?.[0];
      if (options.showOption) {
        setOptions({
          showOption: false,
          selectedOptionUID:
            "" +
            selectedOptionDataValues.accountnumber +
            selectedOptionDataValues.purpose +
            selectedOptionDataValues.createdon,
          selectedOptionData: accountDetails,
        });
      } else {
        setOptions({
          showOption: true,
          selectedOptionUID:
            "" +
            selectedOptionDataValues.accountnumber +
            selectedOptionDataValues.purpose +
            selectedOptionDataValues.createdon,
          selectedOptionData: accountDetails,
        });
      }
      type === "view" ? setShowViewBox(true) : setShowEditBox(true);
      setLoading(false);
      setLoaderMsg("");
    });
  }

  function optionHandler(selectedOptionDataValues, isMenuOpen = null) {
    if (options.showOption || isMenuOpen) {
      setOptions({
        showOption: false,
        selectedOptionUID:
          "" +
          selectedOptionDataValues.accountnumber +
          selectedOptionDataValues.purpose +
          selectedOptionDataValues.createdon,
      });
    } else {
      setOptions({
        showOption: true,
        selectedOptionUID:
          "" +
          selectedOptionDataValues.accountnumber +
          selectedOptionDataValues.purpose +
          selectedOptionDataValues.createdon,
      });
    }
  }

  // ! View Part
  // ! View Box State & Callback
  const [showViewBox, setShowViewBox] = useState(false);

  // ! Close View Callback
  const closeViewCallback = useCallback((value) => {
    setShowViewBox(value);
  }, []);

  // ! Edit Part
  // ! Edit Box State & Callback
  const [showEditBox, setShowEditBox] = useState(false);

  // ! Edit Callback
  const editCallback = useCallback((value) => {
    setShowEditBox(value);
  }, []);

  // ! Modal Part
  // ! Modal State & Callback
  const [showModalBox, setShowModalBox] = useState(null);

  // ! Close Modal Callback (Modal No Click Part)
  const closeModalCallback = useCallback((value) => {
    setShowModalBox(value);
  }, []);

  // !  Modal Yes Click Part & Reload Table Data
  const yesCallback = useCallback(
    (value) => {
      setShowModalBox(value);
      fetchTableData();
    },
    [fetchTableData]
  );

  // Data Tables
  const columns = [
    {
      dataField: "accountnumber",
      text: t("account_number").toUpperCase(),
      sort: true,
    },
    {
      dataField: "accountname",
      text: t("account_name").toUpperCase(),
      sort: true,
    },
    {
      dataField: "CloudType",
      text: t("cloud_type"),
      sort: true,
    },
    {
      dataField: "purpose",
      text: "PURPOSE",
      sort: true,
    },

    {
      dataField: "dummyActionField",
      text: "",
      formatExtraData: options,
      formatter: (cellContent, row) => {
        return (
          <div
            className="custom-table-option-conatiner"
            tabIndex="0"
            onBlur={() => {
              optionHandler(row, "open");
            }}
            onClick={() => {
              optionHandler(row);
            }}
          >
            <img src={dots} alt="action" className="p-2" />
            {options.showOption === true &&
              options.selectedOptionUID ===
                "" + row.accountnumber + row.purpose + row.createdon && (
                <div className="custom-table-option">
                  <ul>
                    <>
                      <li
                        onClick={() => {
                          getAccountDetails(row, "view");
                        }}
                      >
                        {t("view")}
                      </li>
                      <li
                        onClick={() => {
                          getAccountDetails(row, "edit");
                        }}
                      >
                        {t("edit")}
                      </li>
                    </>
                  </ul>
                </div>
              )}
          </div>
        );
      },
    },
  ];

  const RowComponent = ({ cell, row }) => {
    const handleCheckboxSelection = (e, rowData) => {
      const checkboxStatus = e.target.checked;
      const { accountnumber, accountname } = rowData;

      const isExists = checkedItems.includes(accountnumber);
      if (checkboxStatus && !isExists) {
        checkedItemsArr.push(accountnumber);
        setCheckedItems((checkedItems) => [...checkedItems, accountnumber]);
        if (checkedItemsArr?.length === responseData?.length) {
          setSelectAllCheckBox(true);
        } else {
          setSelectAllCheckBox(false);
        }
      } else {
        const filteredItems = checkedItemsArr.filter(
          (item) => item != accountnumber
        );
        checkedItemsArr = filteredItems ? filteredItems : [];
        setCheckedItems(() => [...filteredItems]);
        if (checkedItemsArr?.length === responseData?.length) {
          setSelectAllCheckBox(true);
        } else {
          setSelectAllCheckBox(false);
        }
      }
    };

    return (
      <div className="custom-table-option-conatiner" tabIndex="0">
        <Form.Check
          // key={row?.accountnumber + row?.accountname}
          type="checkbox"
          className={`checkbox-input account-list-checkbox-multiselect`}
          onChange={(e) => handleCheckboxSelection(e, row)}
          defaultChecked={false}
          checked={
            checkedItems?.length > 0
              ? checkedItems.includes(row?.accountnumber)
              : false
          }
        />
      </div>
    );
  };

  const bulkUpdateColumns = [
    {
      isDummyField: true,
      text: "",
      events: {
        onClick: () => {},
      },
      formatter: (cell, row) => (
        <RowComponent cell={cell} row={row}></RowComponent>
      ),
      headerFormatter: (cellContent, row) => {
        const handleMultiSelect = (e, row) => {
          const { checked } = e.target;
          if (checked) {
            setSelectAllCheckBox(true);
            const filterAccountDtails = responseData.map(
              (item) => item?.accountnumber
            );
            checkedItemsArr = filterAccountDtails;
            setCheckedItems(filterAccountDtails);
          } else {
            setSelectAllCheckBox(false);
            setCheckedItems([]);
            checkedItemsArr = [];
          }
        };
        return (
          <>
            <Form.Check
              key={row?.accountnumber}
              type="checkbox"
              className="d-inline checkbox-input account-list-checkbox-multiselect"
              onChange={(e) => handleMultiSelect(e, row)}
              defaultChecked={false}
              checked={selectAllCheckBox}
            />
            <p className="checkbox-select-all">SELECT ALL</p>
          </>
        );
      },
      formatExtraData: options,
    },
    {
      dataField: "accountnumber",
      text: t("account_number").toUpperCase(),
      sort: true,
    },
    {
      dataField: "accountname",
      text: t("account_name").toUpperCase(),
      sort: true,
    },
  ];

  const defaultSorted = [
    // {
    //   dataField: "uid",
    //   order: "desc",
    // },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {t("showing_results", { from, to, size })}
    </span>
  );

  const pageOptions = {
    sizePerPage: 10,
    totalSize: responseData?.length,
    custom: true,
    paginationTotalRenderer: customTotal,
  };

  const { SearchBar } = Search;

  useEffect(() => {
    if (accountRequestReloadType === true) {
      setBulkUpdateEdit(false);
      setShowEditBox(false);
      dispatch({
        type: ACCOUNT_REQUEST_RELOAD,
        payload: false,
      });
    }
    fetchTableData();
    return () => {
      setResponseData([]);
    };
  }, [fetchTableData, accountRequestReloadType, dispatch]);

  const bulkUpdate = (value) => {
    checkedItemsArr = [];
    setCheckedItems([]);
    setSelectAllCheckBox(false);
    setBulkUpdate(value);
  };

  return (
    <div>
      {Loading && <Loader msg={loaderMsg} />}

      {Loading === false && !isBulkUpdate && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={responseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="accountnumber"
              columns={columns}
              data={responseData}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md={6} className="d-flex align-items-center mb-2">
                      <h5 className="page-content-title">
                        {t("account_list")}
                      </h5>
                    </Col>

                    <Col md={1} className="mb-2">
                      <button
                        className="teal-bordered-btn w-100"
                        type="button"
                        onClick={() => {
                          fetchTableData();
                        }}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </Col>
                    <Col md={2} className="mb-2">
                      <button
                        className="teal-filled-btn w-100"
                        type="button"
                        onClick={() => {
                          bulkUpdate(true);
                        }}
                      >
                        Bulk Update
                      </button>
                    </Col>
                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                          placeholder={t("search")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12" className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"id"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {Loading === false && isBulkUpdate && (
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={bulkUpdateColumns}
          data={responseData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="accountnumber"
              columns={bulkUpdateColumns}
              data={responseData}
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md={4} className="d-flex align-items-center mb-2">
                      <h5 className="page-content-title">
                        {t("account_list")}
                      </h5>
                    </Col>

                    <Col md={1} className="mb-2">
                      <button
                        className="teal-bordered-btn w-100"
                        type="button"
                        onClick={() => {
                          fetchTableData();
                        }}
                      >
                        <i className="fa fa-refresh"></i>
                      </button>
                    </Col>
                    <Col md={2} className="mb-2">
                      <button
                        className="teal-filled-btn w-100"
                        type="button"
                        onClick={() => {
                          bulkUpdate(false);
                        }}
                      >
                        {/* <i class="fa fa-less-than"></i> */}
                        Back
                      </button>
                    </Col>
                    <Col md={2} className="mb-2">
                      <button
                        className={`${
                          checkedItems?.length ? "" : "disable-div"
                        } teal-filled-btn w-100`}
                        disabled={!checkedItems?.length}
                        type="button"
                        onClick={() => {
                          setBulkUpdateEdit(true);
                        }}
                      >
                        Proceed
                      </button>
                    </Col>

                    <Col md={3} className="mb-2">
                      <div className="search-input-group">
                        <i className="fa fa-search"></i>
                        <SearchBar
                          {...toolkitProps.searchProps}
                          className="custom-search-input-form-control"
                          placeholder={t("search")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="9" className="mt-4">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"id"}
                          responsive
                          bordered={false}
                          striped={false}
                          defaultSorted={defaultSorted}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={
                            "custom-table-head bulk-update-table-head custom-pointer"
                          }
                          bodyClasses={"custom-table-body"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="align-items-md-center mt-3">
                    <Col className="d-flex flex-wrap justify-content-between align-items-baseline">
                      <div>
                        <PaginationTotalStandalone {...paginationProps} />
                      </div>
                      <div>
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      )}

      {Loading === false && responseData.length === 0 && (
        <div className="d-flex justify-content-center align-items-center">
          No Data to Display
        </div>
      )}

      {/* View Box */}
      {showViewBox && (
        <AccountView
          data={options.selectedOptionData}
          cloudTitle="Azure"
          closeViewParent={closeViewCallback}
          openEditParent={editCallback}
          userInfo={userInfo}
        />
      )}

      {/* Edit Box */}
      {showEditBox && (
        <AccountEdit
          data={options.selectedOptionData}
          cloudTitle="Azure"
          closeEditParent={editCallback}
        />
      )}

      {/* Pending to Approve Modal */}
      {showModalBox === "Approve" && (
        <CustomModal
          showModal={true}
          children={
            <AccountApprove
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending to Decline Modal */}
      {showModalBox === "Decline" && (
        <CustomModal
          showModal={true}
          children={
            <AccountDecline
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending to Cancel Modal */}
      {showModalBox === "Cancel" && (
        <CustomModal
          showModal={true}
          children={
            <AccountCancel
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* Pending Approval for Update to Approve Modal */}
      {showModalBox === "Pending Approval for Update" && (
        <CustomModal
          showModal={true}
          children={
            <AccountApprove
              closeModalParent={closeModalCallback}
              yesParent={yesCallback}
              status={options.selectedOptionData.requestStatus}
              uid={options.selectedOptionUID}
            />
          }
        />
      )}

      {/* // BULK ACCOUNT EDIT */}
      {showBulkUpdateEdit && (
        <BulkAccountEdit
          data={responseData}
          availableData={responseData}
          selectedData={checkedItems}
          cloudTitle="Azure"
          closeEditParent={bulkEditCallback}
        />
      )}
    </div>
  );
};

export default AccountListTable;
